import toastr from 'toastr';
import {Controller} from 'stimulus';
export default class extends Controller {
  static targets = ["errors"]

  checkFile(e) {
    var file = e.target.value;
    var extension = file.substr((file.lastIndexOf('.') +1));
    if (!/(jpg|jpeg|gif|png)$/ig.test(extension)) {
      e.target.value = "";
      this.errorsTarget.textContent = "Invalid file type: "+extension+".  Please use JPG, JPEG, PNG or GIF.";
    }
  };
  checkVideo(e) {
    var file = e.target.value;
    var extension = file.substr((file.lastIndexOf('.') + 1));
    if (!/(mp4|avi|wmv|mkv|3gp)$/ig.test(extension)) {
      e.target.value = "";
      this.errorsTarget.textContent = "Invalid file type: " + extension + ".  Please use MP4, AVI, WMV, MKV or 3GP.";
    }
  };
}