import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"]

  donate() {
    Rails.fire(this.formTarget, "submit");
  }

  onPostSuccess(event){
    let [data, status, xhr] = event.detail;
    const amount = data.amount;
    const url = this.data.element.dataset.checkoutUrl;
    const key = this.data.element.dataset.stripeApiKey;

    Rails.ajax({
      type: "GET",
      url: `${url}&amount=${amount}`,
      success: response => {
        const stripe = Stripe(key);
        stripe.redirectToCheckout({
          sessionId: response.session_id
        }).then(function (result) { });
      }
    })
  }
}
