import { Controller } from "stimulus";

import _ from "lodash";
import Rails from "rails-ujs";

const TARGETS = ["search"];

export default class extends Controller {
  static targets = TARGETS;

  initialize() {
    this.pressKey = _.throttle(this.pressKey, 500);
    this.parseURLParams();
  }

  pressKey() {
    this.submit();
  }

  select() {
    this.submit();
  }

  submit() {
    Rails.fire(this.element, "submit");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${Rails.serializeElement(this.element)}`
    );
  }

  // Conserve the input values when searching for something and
  // then paginating results.
  parseURLParams() {
    TARGETS.forEach(target => {
      const element = this.targets.find(target);

      if (element && this.params.get(target)) {
        element.value = this.params.get(target);
      }
    });
  }

  get params() {
    return new URLSearchParams(window.location.search);
  }
}
