import {Controller} from 'stimulus';
import Awesomplete from "awesomplete";

export default class extends Controller {
    static targets = ["field", "city"]

    initialize() {
        this.awesomplete = new Awesomplete(this.fieldTarget, {
            minChars: 2,
            data: function (item, input) {
                return {label: item.label, value: item.value};
            },
            replace: function (suggestion) {
                this.input.value = suggestion.label;
                $("#traveler_request_city_id").val(suggestion.value);
            },
            filter: function (text, input) {
                var input = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                var text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return text.indexOf(input) === 0;
            },
            sort: false
        });
    }

    fetchSuggestions(e) {
        var resource = e.target.dataset.url;
        var awesomplete = this.awesomplete;
        fetch(`${resource}?search=${e.target.value}`)
            .then(response => response.json())
            .then(json => {
                awesomplete.list = json;
                awesomplete.evaluate();
            });
    }

    get awesomplete() {
        return this.awesompleteInstance;
    }

    set awesomplete(value) {
        this.awesompleteInstance = value;
    }
}
