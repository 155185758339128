document.addEventListener("turbo:load", () => {
  var rangeSlider = function(){
    var slider = $('#rg-s'),
      range = $('#rg-s_range'),
      value = $('#rg-s_value');
      slider.each(function(){
        value.each(function(){
            var value = $(this).prev().attr('value');
            $(this).html(value + " hrs");
        });

        range.on('input', function(){
            $(this).next(value).html(this.value + " hrs");
        });
      });
    };

  var rangeSliderBid = function(){
    var slider = $('#rg-s_bid'),
      range = $('#rg-s_range_bid'),
      value = $('#rg-s_value_bid');
      slider.each(function(){
        value.each(function(){
            var value = $(this).prev().attr('value');
            $(this).html("U$"+ value);
        });

        range.on('input', function(){
            $(this).next(value).html("U$"+this.value);
        });
      });
    };  
  rangeSlider();
  rangeSliderBid();
});