import { Controller } from 'stimulus';
import Awesomplete from "awesomplete";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default class extends Controller {
  static targets = ["field"]

  initialize() {
    this.awesomplete = new Awesomplete(this.fieldTarget, {
      minChars: 4,
      maxItems: 5,
      data: function (item, input) {
        return { label: item.label, value: item.value };
      },
      replace: function (suggestion) {
        this.input.value = suggestion.label;
      },
      filter: function (text, input) {
        var input = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        var text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return text.indexOf(input) === 0;
      },
      sort: false
    });

    this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 300);
  }

  fetchSuggestions(e) {
    debugger
    if (e.target.value.length > 4) {
      var resource = e.target.dataset.url;
      var awesomplete = this.awesomplete;
      fetch(`${resource}?search=${e.target.value}`)
        .then(response => response.json())
        .then(json => {
          awesomplete.list = json;
          awesomplete.evaluate();
        });
    }
  }

  defaultOption(e) {
    var awesomplete = this.awesomplete;
    awesomplete.minChars = 0;
    awesomplete.evaluate();
  }

  suggestionSelected(e) {
    var resource = e.target.dataset.redirect;
    var params = e.target.dataset.params;
    var locationPath = params ? `${resource}${e.text.value}?${params}` : `${resource}${e.text.value}`;

    window.location.href = `${locationPath}`;
  }

  get awesomplete() {
    return this.awesompleteInstance;
  }

  set awesomplete(value) {
    this.awesompleteInstance = value;
  }
}
