import { Controller } from 'stimulus';
export default class extends Controller {
	static targets = ["next", "previous", "destination", "category"]
	toggle(event) {
		event.preventDefault();

		var travelerRequest = document.getElementById("new_traveler_request");
		if (travelerRequest && !travelerRequest.checkValidity()) {
			$("#new_traveler_request").find(":invalid").first().focus();
			return;
		}

		var agencyRequest = document.getElementById("new_agency_request");
		if (agencyRequest && !agencyRequest.checkValidity()) {
			$("#new_agency_request").find(":invalid").first().focus();
			return;
		}

		this.destinationTarget.classList.toggle("slide--current");
		this.categoryTarget.classList.toggle("slide--current");
		this.previousTarget.classList.toggle("slide--current");
		this.nextTarget.classList.toggle("is-hidden");
	}
}
