import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hint", "rating"];

  choose(event) {
    const rating = parseInt(event.currentTarget.dataset.rating);
    this.chooseIndividual(rating);
  }

  chooseIndividual(rating) {
    for (let el of this.ratingTargets) {
      let currentRating = parseInt(el.dataset.rating);
      if (currentRating <= rating) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    }
  }
}