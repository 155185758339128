import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  subscribe() {
    const url = this.data.element.dataset.checkout_url;
    const key = this.data.element.dataset.stripe_api_key;

    Rails.ajax({
      type: "GET",
      url: url,
      success: response => {
        const stripe = Stripe(key);
        stripe.redirectToCheckout({
          sessionId: response.session_id
        }).then(function (result) { });
      }
    });
  }
}
