import Pikaday from "pikaday";
import "pikaday/scss/pikaday";
import moment from "moment";

document.addEventListener("turbo:load", () => {
    var $elements = Array.prototype.slice.call(
        document.querySelectorAll(".pikaday"),
        0
    );
    if ($elements.length > 0) {
        $elements.forEach($el => {
            new Pikaday({
                setDefaultDate: true,
                field: $el,
                yearRange: [1900, (new Date().getFullYear())],
                format: "ll",
                minDate: moment().toDate(),
                onSelect: () => {
                    $el.dispatchEvent(new Event("input"));
                }
            });
        });
    }
});
