/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import "@hotwired/turbo-rails";

import Rails from "rails-ujs";
Rails.start();

// Utility function to require all files in a directory...
function requireAll(r) {
  r.keys().forEach(r);
}

// Require everything in our modules directory
requireAll(require.context("./modules/", true, /\.js$/));

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /.js$/);
application.load(definitionsFromContext(context));

// Add images to the manifest
requireAll(require.context("images/", true, /\.(svg|png|gif|jpg)$/));

// Import our app CSS
import "css/application";