document.addEventListener("turbo:load", () => {

  var $notifications = Array.prototype.slice.call(document.querySelectorAll('.notification .delete'), 0);

  if ($notifications.length > 0) {
    $notifications.forEach(function ($el) {
      $parent = $el.parentNode;
      $el.addEventListener("click", event => {
        $parent.parentNode.removeChild($parent);
      });
    });
  }
});