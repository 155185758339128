import { Controller } from "stimulus";

const TARGETS = ["localFriendHint", "virtualFriendHint"];

export default class extends Controller {
  static targets = TARGETS;

  choose(event) {
    this.virtualFriendHintTarget.classList.toggle('is-hidden');
    this.localFriendHintTarget.classList.toggle('is-hidden');
  }

  check(event) {
    if (event.target.checked) {
      if (event.target.value == 'local') {
        this.localFriendHintTarget.classList.toggle('is-hidden');
      }
      else {
        this.virtualFriendHintTarget.classList.toggle('is-hidden');
      }
    }
    else {
      if (event.target.value == 'local') {
        this.localFriendHintTarget.classList.toggle('is-hidden');
      }
      else {
        this.virtualFriendHintTarget.classList.toggle('is-hidden');
      }
    }
  }
}
