import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output", "input"]

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }

    var remove_el = document.getElementById('remove-image');
    if (remove_el) {
      remove_el.classList.toggle('hidden');
    }
  }
}