import bulmaCarousel from 'bulma-carousel';

document.addEventListener("turbo:load", () => {
  bulmaCarousel.attach('#carousel-friend-page', {
    slidesToScroll: 1,
    slidesToShow: 2,
    breakpoints: [{ changePoint: 480, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 640, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 768, slidesToShow: 1, slidesToScroll: 1 }],
    loop: true,
    infinite: false,
    pagination: false
  });

  bulmaCarousel.attach('#carousel-how-does-it-work', {
    slidesToScroll: 4,
    slidesToShow: 4,
    breakpoints: [{ changePoint: 480, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 640, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 768, slidesToShow: 1, slidesToScroll: 1 }],
    pagination: false
  });

  bulmaCarousel.attach('#carousel-travelers', {
    slidesToScroll: 3,
    slidesToShow: 3,
    breakpoints: [{ changePoint: 480, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 640, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 768, slidesToShow: 1, slidesToScroll: 1 }],
    pagination: false
  });

  bulmaCarousel.attach('#local-carousel', {
    slidesToScroll: 5,
    slidesToShow: 5,
    breakpoints: [{ changePoint: 480, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 640, slidesToShow: 1, slidesToScroll: 1 },
    { changePoint: 768, slidesToShow: 1, slidesToScroll: 1 }],
  });
});