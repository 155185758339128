document.addEventListener("turbo:load", () => {

  var $dropdowns = Array.prototype.slice.call(document.querySelectorAll('.dropdown:not(.is-hoverable)'), 0);

  if ($dropdowns.length > 0) {
    $dropdowns.forEach(function ($el) {
      $el.addEventListener("click", event => {
        event.stopPropagation();
        $el.classList.toggle("is-active");
      });
    });
  }
});